import React, { useState, useEffect, useRef } from "react";
import Snowfall from "react-snowfall";
import new_pic from "../images/3d_ill_new.png";
import soundFile from "../audios/georgethecoder_Yo.mp3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import Typewriter from "typewriter-effect";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Logo from "../images/georgethecoder_p.png";

const SocialLink = styled.a`
  transition: transform 0.3s ease;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 375px) {
    font-size: 1.75rem;
    margin: 0 5px;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
    color: var(--custom-new-blue);
  }
`;

const Description = () => (
  <>
    <p className="text-md md:text-lg lg:text-xl leading-relaxed tracking-wide text-center lg:text-left mb-4">
      ...or just George. I'm a dedicated developer with a passion for turning
      code into interfaces and applications for global businesses.
    </p>
    <p className="text-md md:text-lg lg:text-xl leading-relaxed tracking-wide mt-4 lg:mt-6 text-center lg:text-left">
      My analytical mindset, combined with an insatiable curiosity, pushes me to
      constantly expand my knowledge and skills.
    </p>
  </>
);

const socialMediaLinks = [
  {
    href: "https://el-gr.facebook.com/people/%CE%93%CE%B9%CF%8E%CF%81%CE%B3%CE%BF%CF%82-%CE%94%CE%B9%CE%AC%CE%BA%CE%BF%CF%82/pfbid02Xu9niPnA52JPK4fa3HZkZYdxkCfCWXR3RdR3sZ4Ujtb1DadZSUHci1qE6Ya8YU7Ql/",
    icon: faFacebook,
    label: "Facebook",
  },
  {
    href: "https://www.instagram.com/georgethecoder/",
    icon: faInstagram,
    label: "Instagram",
  },
  {
    href: "https://gr.linkedin.com/in/george-diakos-0a3983197?challengeId=AQH53k-DYPUzowAAAYsQoNx8G6hODNQI3q8j2zCk_d4rhIn_hA9A5qdB7BDbK2Xq8V9YZTHxtZzMzmxIFkVhoJWV03ijaXS-tw&submissionId=6d27297a-7a36-8c17-913c-14d729855f0f&challengeSource=AgFYtEDlY-dsFwAAAYsQoSxZy6hbPg3qtMZF7E7_6HYseFcZo-AoAiChmJz_TkM&challegeType=AgH0nLuAQgBRJQAAAYsQoSxcpkIqI9cWZ6BIc10sZBbsXGYgYWMtq34&memberId=AgHMsdnppekUdQAAAYsQoSxfaug7JLaSo9wkN6ShD7o8PF0&recognizeDevice=AgHEsjE8J-VY1AAAAYsQoSxijWidpSITQ6YrKVk3e7VpEvxhkSoz",
    icon: faLinkedin,
    label: "LinkedIn",
  },
  {
    href: "mailto:georgethecoder@yahoo.com",
    icon: faEnvelope,
    label: "Email",
  },
  {
    href: "https://twitter.com/georgethecoder",
    icon: faXTwitter,
    label: "Twitter",
  },
];

const commonIconClasses =
  "transition-transform transform hover:scale-105 text-5xl";

const isSnowSeason = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const startSnowDate = new Date(`${year}-12-01`);
  const endSnowDate = new Date(`${year}-01-07`);
  return currentDate >= startSnowDate || currentDate <= endSnowDate;
};

const isShonwAgain = () => {
  const today = new Date();
  const allYear = today.getFullYear();
  const startSnowToday = new Date(`${allYear}-12-01`);
  const endSnowTommorow = new Date(`${allYear}-01-07`);
  return today >= startSnowToday || today <= endSnowTommorow;
};

const Home = () => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [audio, setAudio] = useState(null);
  const homeRef = useRef(null); // Create a ref to the Home section

  useEffect(() => {
    const newAudio = new Audio(soundFile);
    setAudio(newAudio);

    const playAudio = () => {
      newAudio
        .play()
        .then(() => {
          setIsSpeaking(true);
        })
        .catch((error) => {
          console.error("Audio playback failed:", error);
        });

      newAudio.addEventListener("ended", () => {
        setIsSpeaking(false);
      });
    };

    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    const handleInteraction = () => {
      playAudio();
      if (homeRef.current) {
        homeRef.current.removeEventListener("click", handleInteraction);
        homeRef.current.removeEventListener("scroll", handleInteraction);
      }
    };

    if (isMobileDevice) {
      // Play audio on mobile after a user interaction (click or scroll) on the entire home page
      if (homeRef.current) {
        homeRef.current.addEventListener("click", handleInteraction);
        homeRef.current.addEventListener("scroll", handleInteraction);
      }
    } else {
      // Play audio immediately on desktop
      playAudio();
    }

    return () => {
      newAudio.pause();
      newAudio.currentTime = 0; // Reset the audio to the beginning
      if (homeRef.current) {
        homeRef.current.removeEventListener("click", handleInteraction);
        homeRef.current.removeEventListener("scroll", handleInteraction);
      }
    };
  }, [homeRef]);

  const toggleMute = () => {
    if (audio) {
      if (isMuted) {
        audio.muted = false;
        setIsMuted(false);
      } else {
        audio.muted = true;
        setIsMuted(true);
      }
    }
  };

  return (
    <>
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      <Helmet>
        <title>Home - georgethecoder.com</title>
        <link rel="icon" href={Logo} type="image/png" />
      </Helmet>

      <section
        id="Home"
        ref={homeRef} // Attach ref to the section
        className="flex flex-col lg:flex-row lg:h-screen bg-gradient-to-br from-blue-600 to-blue-800 text-white p-4 md:p-8 lg:p-12 overflow-hidden"
      >
        <article className="relative flex-1 flex flex-col justify-center items-center space-y-4 md:space-y-6 lg:space-y-8 z-10 text-center lg:text-left">
          <h1 className="text-lg md:text-xl lg:text-3xl font-extrabold leading-tight mb-4 md:mb-6 lg:mb-8 mt-4 md:mt-0">
            Hello, I am...
          </h1>

          <div className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-4 md:mb-6 lg:mb-8">
            <Typewriter
              options={{
                strings: ["GEORGETHECODER"],
                autoStart: true,
                loop: true,
              }}
            />
          </div>

          <Description />

          <div className="social-links-container flex flex-wrap justify-center lg:justify-start space-x-2 md:space-x-4 mt-4 md:mt-5 lg:mt-6 gap-2 z-20">
            {socialMediaLinks.map((link) => (
              <SocialLink
                key={link.href}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={link.label}
              >
                <FontAwesomeIcon
                  icon={link.icon}
                  className={commonIconClasses}
                />
              </SocialLink>
            ))}
          </div>
        </article>

        <div className="flex flex-1 items-center justify-center p-4 md:p-6 lg:p-8 z-10 mt-4 md:mt-6 lg:mt-0 w-full max-w-sm md:max-w-md lg:max-w-lg">
          {isShonwAgain() && <Snowfall snowflakeCount={50} />}
          <div className="relative">
            {isSpeaking && (
              <div
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full mb-2 cursor-pointer"
                onClick={toggleMute}
              >
                <div
                  className="bg-white text-black text-xs px-3 py-1 rounded-full shadow-lg"
                  style={{
                    border: "1px solid #ccc",
                    fontSize: "1.5rem",
                  }}
                >
                  <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                </div>
              </div>
            )}
            <img
              src={new_pic}
              alt="Sample Display"
              className="w-full h-auto object-contain"
              style={{
                border: "none",
                boxShadow: "none",
                background: "none",
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
