import React, { useState } from "react";
import { Search, X } from "lucide-react";
import { Helmet } from "react-helmet";
import Logo from "../images/georgethecoder_p.png";
import Snowfall from "react-snowfall";

const calculateAge = (birthDate: string) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
};

const faqData = [
  {
    question: "How old are you?",
    answer: () =>
      `I was born in November 2000, so today I am ${calculateAge(
        "2000-11-22"
      )}. 👴🏻`,
  },
  {
    question: "What programming languages you know?",
    answer:
      "I am specialized in languages related to web development , HTML5, CSS3 , Tailwind CSS and Javascript , Frameworks such as React , I also know Strapi and I know how to use Git control system. Other languages I know in general are , C and Java. I also know mySQL. 🚀",
  },
  {
    question: "How do you typically spend your leisure time?",
    answer:
      "In my leisure time, I often engage in coding, as it is a significant part of my life. Additionally, I enjoy going for walks with my girlfriend and friends, playing basketball, and weightlifting.",
  },
  {
    question: "Where do you live?",
    answer:
      "I reside in Athens, a vibrant city known for its beauty and thriving developer community. Naturally, I spend much of my time away from Athens, as I have a passion for traveling. 🧳",
  },
  {
    question: "Do you have a degree?",
    answer:
      "Yes, I hold a Bachelor's Degree in Information Technology (IT) since October 2023. 🎓",
  },
  {
    question: "What university have you attended?",
    answer:
      "The Department of Informatics of the University of Western Macedonia in the beautiful provincial town of Kastoria in Greece. I graduated in 2023. 🎓",
  },
  {
    question: "What's your favourite editor?",
    answer:
      "I generally use VSC for all programming languages. I also use for the web part editors like Jet Brains' Web Storm , for Java IntelliJ and many others , my two favorites I would say are IntelliJ and VSC. Also Jet Brains puts out very interesting editors. If you are a student you are most likely entitled to some enterprise editors for free. 🥳",
  },
];

const isSnowSeason = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  // Create the start and end dates using MM/DD/YYYY format
  const startSnowDate = new Date(`${year}-12-01`); // December 1st of the current year
  const endSnowDate = new Date(`${year}-01-07`); // January 7th of the next year

  return currentDate >= startSnowDate || currentDate <= endSnowDate;
};

const FAQItem = ({
  question,
  answer,
}: {
  question: string,
  answer: string | (() => string),
}) => (
  <div className="mb-10 last:mb-0">
    <h2 className="text-xl font-semibold text-blue-300 mb-3">{question}</h2>
    <p className="text-white text-base leading-relaxed">
      {typeof answer === "function" ? answer() : answer}
    </p>
  </div>
);

export default function FAQPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFAQs, setFilteredFAQs] = useState(faqData);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const handleSearch = (query: string) => {
    if (query.trim() === "") {
      setFilteredFAQs(faqData);
    } else {
      const filtered = faqData.filter((item) =>
        item.question.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredFAQs(filtered);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    if (isSearchVisible) {
      setSearchQuery("");
      setFilteredFAQs(faqData);
    }
  };

  return (
    <>
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      <Helmet>
        <title>FAQs - www.georgethecoder.com</title>
        <link rel="icon" href={Logo} type="image/png" />
      </Helmet>

      <div className="container mx-auto p-4 md:p-6 lg:p-8">
        <div className="max-w-full px-4 py-6 sm:px-6 sm:py-8 md:px-8 md:py-10">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-8 gap-4">
            <h1 className="text-4xl sm:text-4xl md:text-6xl text-white">
              FAQs
            </h1>
            <div className="flex items-center w-full sm:w-auto">
              {isSearchVisible && (
                <input
                  type="text"
                  placeholder="Search questions..."
                  value={searchQuery}
                  onChange={handleInputChange}
                  className="flex-grow mr-2 p-2 rounded-md text-black w-full sm:w-auto text-sm sm:text-base"
                />
              )}
              <button
                onClick={toggleSearch}
                className="bg-blue-500 text-white p-2 sm:p-3 w-10 h-10 sm:w-12 sm:h-12 rounded-full flex items-center justify-center transition-all duration-400 ease-in-out hover:bg-blue-600"
                aria-label={isSearchVisible ? "Close search" : "Open search"}
              >
                {isSearchVisible ? (
                  <X className="w-4 h-4 sm:w-5 sm:h-5" />
                ) : (
                  <Search className="w-4 h-4 sm:w-5 sm:h-5" />
                )}
              </button>
            </div>
          </div>
          <div className="space-y-8">
            {filteredFAQs.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </div>
      </div>
      <style jsx>{`
        .duration-400 {
          transition-duration: 400ms;
        }
      `}</style>
    </>
  );
}
