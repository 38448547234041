import React from "react";
import { FaSmile } from "react-icons/fa";
import { useState } from "react";

const ContactForm = () => {
  const contactInfo = {
    Address: "Athens, Greece",
    email: "georgethecoder@yahoo.com",
    Website: "www.georgethecoder.com",
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Using state variables directly in mailtoString
    const mailtoString = `mailto:georgethecoder@yahoo.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nMessage: ${message}`
    )}`;

    window.location.href = mailtoString;

    // Reset the form fields immediately upon submission
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");

    setIsLoading(true);
    setProgress(0);

    // Simulate a task by incrementing the progress
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          setIsLoading(false);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 100); // Adjust time here as needed for your task
  };

  return (
    <div
      id="communicationForm"
      className="pt-12 pb-12 animate-fadeIn relative font-poppins text-base leading-[30px] font-medium text-[#051923] "
    >
      <div className="px-5 md:px-20 block">
        <div className="mx-auto max-w-[1300px] clearfix">
          <h2 className="text-2xl md:text-4xl lg:text-6xl font-extrabold leading-snug mb-3 md:mb-5 lg:mb-7 tracking-tighter text-custom-new-blue serif-font">
            Get in touch
          </h2>
          <div className="w-full  md:w-1/2 mb-12 float-left relative pr-5 pl-0">
            <p className="mt-2 mb-4 text-white text-xl">
              Whether you're curious about a new project, have questions related
              to programming, or just have something on your mind, feel free to
              send me a message. I'll respond at my earliest convenience.{" "}
              <FaSmile size="2rem" className="inline-block " />
            </p>
            <address className="text-lg text-white">
              <strong>ADDRESS:</strong>{" "}
              <a href="https://www.google.com/maps/place/Google+Athens/@38.03902,23.804595,15z/data=!4m2!3m1!1s0x0:0x884d2d14811aae48?sa=X&ved=2ahUKEwiSjcWhsL-DAxUn87sIHURXDFwQ_BJ6BAhFEAA">
                {contactInfo ? contactInfo.Address : "Loading..."}
              </a>{" "}
              <br />
              <strong className="">EMAIL:</strong>{" "}
              {contactInfo ? contactInfo.email : "Loading..."} <br />
              <strong>WEBSITE:</strong>{" "}
              <a href="https://www.georgethecoder.com">
                {contactInfo ? contactInfo.Website : "Loading..."}
              </a>{" "}
              <br />
            </address>
          </div>

          <div className="text-lg sm:text-xl leading-[30px] sm:leading-[35px] mb-6 w-full md:w-1/2 float-left ml-[-4] md:ml-0 md:pl-5">
            <form
              id="contact"
              onSubmit={handleSubmit}
              className="p-10 shadow-photo md:p-16 bg-gradient-to-r from-[#024a80] to-[#013b5e] animate-gradient rounded-lg shadow-lg"
            >
              <div className="mb-5">
                <label htmlFor="name" className="sr-only">
                  Όνομα
                </label>
                <input
                  id="name"
                  type="text"
                  name="your-name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full p-3 rounded-md bg-opacity-20 bg-white border-b-2 border-white hover:border-[#0582ca] transition-all duration-300 text-white"
                />
              </div>

              <div className="mb-5">
                <label htmlFor="contact-email" className="sr-only">
                  Email
                </label>
                <input
                  id="contact-email"
                  type="email"
                  name="your-email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-3 rounded-md bg-opacity-20 bg-white border-b-2 border-white hover:border-[#0582ca] transition-all duration-300 text-white"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="subject" className="sr-only">
                  Θέμα
                </label>
                <input
                  id="subject"
                  type="text"
                  name="your-subject"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                  className="w-full p-3 rounded-md bg-opacity-20 bg-white border-b-2 border-white hover:border-[#0582ca] transition-all duration-300 text-white"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="message" className="sr-only">
                  Μήνυμα
                </label>
                <textarea
                  id="message"
                  name="your-message"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full p-3 rounded-md bg-opacity-20 bg-white border-b-2 border-white h-40 hover:border-[#0582ca] transition-all duration-300 text-white"
                ></textarea>
              </div>
              <div className="mb-0">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#0582ca] w-full hover:bg-[#036aa1] hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0582ca] bounce text-white py-3 px-6 rounded-md cursor-pointer transition-all duration-300 floating"
                >
                  {isLoading ? `Sending... (${progress}%)` : "SEND"}
                </button>

                {/* Loading bar */}
                {isLoading && (
                  <div
                    style={{
                      width: `${progress}%`,
                      backgroundColor: "blue",
                      height: "5px",
                      marginTop: "10px",
                    }}
                  ></div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
