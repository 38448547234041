import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Snowfall from "react-snowfall";
import george from "../images/george.png";

const NAV_ITEMS = [
  { label: "Home", link: "/#Home", isExternal: true },
  { label: "About Me", link: "/#About", isExternal: false },
  { label: "Tech Stack", link: "/#Tech", isExternal: false },
  { label: "Projects", link: "/projects", isExternal: true },
  { label: "Contact", link: "/#communicationForm", isExternal: false },
  { label: "FAQs", link: "/FAQs", isExternal: true },
];

const USER_INFO = {
  name: "George Diakos",
  role: "Front-End Developer",
  email: "georgethecoder@yahoo.com",
  avatar: george,
};

const LeftWrapperBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavLinkClick = (isExternal, link, event) => {
    if (!isExternal) {
      event.preventDefault();

      if (link === "/" || link === "/#Home") {
        if (location.pathname === "/") {
          window.scrollTo(0, 0);
        } else {
          navigate("/", { replace: true });
          setTimeout(() => window.scrollTo(0, 0), 0);
        }
      } else {
        const sectionId = link.split("/#")[1];
        if (location.pathname !== "/") {
          navigate("/", { replace: true });
          setTimeout(() => scrollToSection(sectionId), 0);
        } else {
          scrollToSection(sectionId);
        }
      }
    }
    setIsSidebarOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const HamburgerIcon = ({ isOpen }) => (
    <div className={`hamburger-menu ${isOpen ? "open" : ""}`}>
      <span
        className={`hamburger-line top-line ${isOpen ? "open" : ""}`}
      ></span>
      <span
        className={`hamburger-line middle-line ${isOpen ? "open" : ""}`}
      ></span>
      <span
        className={`hamburger-line bottom-line ${isOpen ? "open" : ""}`}
      ></span>
    </div>
  );

  const NavBar = () => (
    <div className="fixed top-0 left-0 right-0 z-30 flex justify-between items-center p-4 bg-custom-blue md:hidden">
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="flex justify-center items-center p-2 mb-2"
        aria-label={isSidebarOpen ? "Close Menu" : "Open Menu"}
      >
        <HamburgerIcon isOpen={isSidebarOpen} />
      </button>
      <div className="text-white text-sm font-bold float">
        Keep Coding{" "}
        <span role="img" aria-label="rocket" className="inline-block ml-2">
          🚀
        </span>
      </div>
    </div>
  );

  const isSnowSeason = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const startSnowDate = new Date(`${year}-12-01`);
    const endSnowDate = new Date(`${year}-01-07`);
    return currentDate >= startSnowDate || currentDate <= endSnowDate;
  };

  const SidebarContent = () => (
    <div className="p-2 text-center h-full overflow-y-auto">
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      <img
        src={USER_INFO.avatar}
        alt="User Avatar"
        className="hidden md:block mx-auto w-32 h-32 rounded-full border-4 border-white shadow-lg mb-4"
      />
      <nav className="mb-8">
        <ul className="pt-12 font-bold">
          {NAV_ITEMS.map((item) => (
            <li
              key={item.label}
              className="text-left lg:pl-32 pl-16 mb-2 p-2 rounded text-3xl"
            >
              {item.isExternal ? (
                <Link
                  to={item.link}
                  className="text-white hover-bg-effect rounded transition duration-300"
                  data-text={item.label}
                  onClick={(e) =>
                    handleNavLinkClick(item.isExternal, item.link, e)
                  }
                >
                  {item.label}
                </Link>
              ) : (
                <a
                  href={item.link}
                  onClick={(e) =>
                    handleNavLinkClick(item.isExternal, item.link, e)
                  }
                  className="text-white hover-bg-effect rounded transition duration-300"
                  data-text={item.label}
                >
                  {item.label}
                </a>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="pt-4">
        <InfoItem label="Name" value={USER_INFO.name} />
        <InfoItem label="Role" value={USER_INFO.role} />
        <InfoItem label="Email" value={USER_INFO.email} />
      </div>
    </div>
  );

  return (
    <>
      <NavBar />
      <div
        className={`fixed inset-y-0 left-0 z-20 w-64 bg-custom-blue transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 md:static md:w-80 lg:w-96 h-screen overflow-hidden`}
      >
        <SidebarContent />
      </div>
    </>
  );
};

const InfoItem = ({ label, value }) => (
  <>
    <div className="text-blue-500 mb-2">{label}</div>
    <div className="text-white mb-4">{value}</div>
  </>
);

export default LeftWrapperBar;
