import React from "react";
import kastoria from "../images/kastoria.png";
import Snowfall from "react-snowfall";
import { FaSmile } from "react-icons/fa";
import { useRef } from "react";

const ImageSection = () => {
  const isSnowSeason = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const startSnowDate = new Date(`${year}-12-01`);
    const endSnowDate = new Date(`${year}-01-07`);
    return currentDate >= startSnowDate || currentDate <= endSnowDate;
  };

  return (
    <div className="flex-1 flex items-center justify-center p-4 md:p-8 lg:p-10 mt-6 md:mt-8 lg:mt-0 bg-custom-blue">
      {/* Container for image and snow effect */}
      <div className="relative rounded overflow-hidden shadow-photo transform transition-transform hover:scale-105 active:scale-95">
        {/* Snowfall Component */}
        {isSnowSeason() && (
          <div className="absolute top-0 left-0 w-full h-full z-20">
            <Snowfall snowflakeCount={50} />
          </div>
        )}

        {/* Image */}
        <img
          src={kastoria}
          alt="About Illustration"
          className="w-full h-full object-cover  "
        />
      </div>
    </div>
  );
};

const ContentSection = () => {
  const downloadButtonRef = useRef(null); // Define the useRef hook inside the component

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = process.env.PUBLIC_URL + "/George_Diakos_CV_.pdf";
    downloadLink.download = "George_Diakos_CV_.pdf";
    document.body.appendChild(downloadLink);

    // Use the ref to add the animation class
    if (downloadButtonRef.current) {
      downloadButtonRef.current.classList.add("button-clicked");

      // Remove the class after the animation is done
      setTimeout(() => {
        if (downloadButtonRef.current) {
          downloadButtonRef.current.classList.remove("button-clicked");
        }
      }, 400);
    }

    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const isSnowSeason = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // Create the start and end dates using MM/DD/YYYY format
    const startSnowDate = new Date(`${year}-12-01`); // December 1st of the current year
    const endSnowDate = new Date(`${year}-01-07`); // January 7th of the next year

    // Check if current date is within the snow season
    return currentDate >= startSnowDate || currentDate <= endSnowDate;
  };

  return (
    <div
      id="About"
      className="relative flex-1 flex flex-col justify-center p-4 md:p-12 lg:p-24 space-y-6 md:space-y-10 lg:space-y-14 z-10 bg-custom-blue"
    >
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      <h1 className="text-2xl md:text-4xl lg:text-6xl font-extrabold leading-snug mb-3 md:mb-5 lg:mb-7 tracking-tighter text-custom-new-blue serif-font">
        About Me
      </h1>
      <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-3 md:mb-5 lg:mb-7 sans-serif-font">
        The Journey So Far...
      </h2>
      <p className="text-md md:text-lg lg:text-xl leading-relaxed tracking-wide serif-font">
        In 2012, I embarked on a fascinating journey into the realm of software.
        It was evident that I had found my calling. I delved deep, acquiring a
        Bachelor's degree in Information Technology (ΙΤ).
      </p>
      <p className="text-md md:text-lg lg:text-xl leading-relaxed tracking-wide mt-6 serif-font">
        By July 2023, I had evolved into a competent Front-End Developer. The
        very next month, in August 2023, I embraced the challenge of a Project
        Manager role during my internship. My eclectic blend of academic
        learning, freelancing ventures, and professional stints in both
        Front-End Development and Project Management uniquely positions me to
        craft unparalleled digital experiences for you.
        <FaSmile className="inline-block ml-2" />
      </p>

      {/* Download Resume button */}
      <button
        ref={downloadButtonRef}
        onClick={handleDownload}
        className="button-beautiful floating"
      >
        Download Resume
      </button>
    </div>
  );
};

const About = () => {
  return (
    <div className="flex flex-col lg:flex-row  bg-gradient-to-b from-custom-light-blue to-custom-blue text-white container mx-auto overflow-hidden transition-all ease-in-out duration-500">
      <ImageSection />
      <ContentSection />
    </div>
  );
};

export default About;
