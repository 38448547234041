import React from "react";

const Footer = () => {
  return (
    <div className="bg-[#071923] text-white py-6 font-poppins w-full">
      <div className="text-center text-sm md:text-base leading-7 font-normal pt-4">
        © georgethecoder.com 2023 | Developed by ME
        <span role="img" aria-label="rocket" className="inline-block ml-2">
          🚀
        </span>
      </div>
    </div>
  );
};

export default Footer;
